import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./sass/app.scss";
import { addMetaTags } from "./utils/metaTags";

const $app = createApp(App);

/* Default title tag */
const defaultDocumentTitle = "Gaurav Sachdeva";

router.beforeEach(addMetaTags);

router.afterEach((to) => {
  /* Set document title from route meta */
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`;
  } else {
    document.title = defaultDocumentTitle;
  }
});

$app.use(router);
$app.mount("#app");
