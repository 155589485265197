import { HeaderLinks as HeaderLinksType } from "@/types/Menu.type";
  
export const BlogLinks: HeaderLinksType = [
    {
      name: "Home",
      routeName: "Home",
    },
    {
      name: "- Photography",
      routeName: "TagPosts",
      routeParams: { tag: "photography" },
    },
    {
      name: "- Sketching",
      routeName: "TagPosts",
      routeParams: { tag: "sketching" },
    },
    {
      name: "- Calligraphy",
      routeName: "TagPosts",
      routeParams: { tag: "calligraphy" },
    },
    {
      name: "About",
      external: true,
      link: "https://me.gauravsachdeva.in",
    },
    {
      name: "Email",
      external: true,
      link: "mailto:me@gauravsachdeva.in",
    },
  ];