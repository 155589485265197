import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "app" }
const _hoisted_2 = {
  key: 0,
  class: "links-menu"
}
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "content-inner" }
const _hoisted_5 = { class: "flex-basis-min-content" }
const _hoisted_6 = {
  key: 0,
  class: "fixed-header"
}
const _hoisted_7 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_links_menu = _resolveComponent("links-menu")!
  const _component_intro = _resolveComponent("intro")!
  const _component_portfolio_menu = _resolveComponent("portfolio-menu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isBlog)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_links_menu)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (!_ctx.isBlog)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_intro),
                _createVNode(_component_portfolio_menu)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_router_view, null, {
              default: _withCtx(({ Component, route }) => [
                _createVNode(_Transition, { name: "slide" }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                      class: _normalizeClass(`page-${route.name}`.toLowerCase())
                    }, null, 8, ["class"]))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}