import { createRouter, createWebHistory } from "vue-router";

import { Domains as DomainConfigs } from "@/config/domains"
import { isBlogDomain, isPortfolioDomain } from "@/utils/domain";

const routes: any = () => {
  let $routes
  if (isPortfolioDomain()) {
    $routes = DomainConfigs.portfolio.routes;
    (window as any).$routesType = 'portfolio';
  } else if(isBlogDomain()) {
    // If you want to do something else just comment the line below
    $routes = DomainConfigs.default.routes;
    (window as any).$routesType = 'blog';
  } else {
    throw new Error('Domain not found')
  }

  $routes = [
    ...$routes,
    {
      path: "/:catchAll(.*)",
      name: "PageNotFound",
      component: () => {
        return import(/* webpackChunkName: "404" */ "@/views/PageNotFound.vue");
      },
      meta: {
        title: "Page not found",
      },
    },
  ]
  return $routes;
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes(),
});

export default router;
