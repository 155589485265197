
import { defineComponent } from "vue";
import Avatar from "../Avatar.vue";
import SocialLinks from "../SocialLinks.vue";
import Info from "@/config/info";

export default defineComponent({
  components: { Avatar, SocialLinks },
  setup() {
    return {
      Info,
    };
  },
});
