
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    image: {
      type: String,
      default: "/images/main-square-md.jpg",
      // default: "/images/profile.jpg",
    },
    alt: {
      type: String,
      default: "Gaurav Sachdeva",
    },
  },
});
