import { calculateAge } from "@/utils/date";

export const FirstName = "Gaurav"
export const LastName = "Sachdeva"

export const Name = {
    first: FirstName,
    last: LastName,
}

export const DOB = "1997-11-29"

export const ShortDescription = `${calculateAge(DOB)}-Year-Old Software Engineer who loves to do photography, sketching and calligraphy.`

export default {
    Name,
    FirstName,
    LastName,
    DOB,
    ShortDescription,
}
