import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9b2054d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar relative" }
const _hoisted_2 = ["data-img-src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      "data-img-src": _ctx.image,
      class: "image",
      alt: _ctx.alt,
      style: _normalizeStyle(`background-image: url('${_ctx.image}')`)
    }, null, 12, _hoisted_2)
  ]))
}