
export const getCurrentDomain = () => {
    /**
     * Setting up router for sub domain levels
     * @see https://stackoverflow.com/questions/41654181/vue-js-vue-router-subdomains
     */
    const host = window.location.host
    const parts = host.split('.')
    return parts
  }
  
export const getCurrentSubdomain = () => getCurrentDomain()[0]

export const getEnvOverrideDomain = () => {
  return process.env.VUE_APP_DOMAIN_OVERRIDE
}

export const hasOverridenDomain = () => {
  if(getEnvOverrideDomain()) {
    return true;
  }  
  return false;
}  


export const isPortfolioDomain = () => {
  if(getEnvOverrideDomain() == "portfolio") { 
    return true;
  }
  return ["portfolio", "me"].includes(getCurrentSubdomain())
}

export const isBlogDomain = () => {
  if(getEnvOverrideDomain() == "blog") { 
    return true;
  }
  return ["www"].includes(getCurrentSubdomain())
}
