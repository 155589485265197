
import { defineComponent } from "vue";
import LinksMenu from "./components/LinksMenu.vue";
import { isBlogDomain } from "./utils/domain";
import Intro from "./components/Home/Intro.vue";
import PortfolioMenu from "./components/Links/PortfolioMenu.vue";

export default defineComponent({
  components: {
    LinksMenu,
    Intro,
    PortfolioMenu
  },
  setup(){
    return {
      isBlog: isBlogDomain(),
    }
  }
});
