<template>
    <div class="portfolio-menu">
        <ul v-if="menuLinks.length > 0">
            <li v-for="link in menuLinks" :key="link">
                <component
                    :is="link.external ? 'a' : 'router-link'"
                    :rel="link.external ? 'noreferrer' : null"
                    :href="link.link"
                    :to="{ name: link.routeName, params: link.routeParams }"
                    v-text="link.name"
                ></component>
                <span
                    class="external-link-notice"
                    v-if="link.external"
                    title="External Link"
                >
                    <img
                        :src="`/images/link-external-icon.svg`"
                        width="20"
                        height="20"
                        alt="External Link"
                    />
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
import { HeaderLinks } from "@/config/menu";

export default {
    setup() {
        return {
            menuLinks: HeaderLinks
        }
    },
}
</script>