import { SocialLinkType } from "@/types/Social.type";


export const SocialLinks: Array<SocialLinkType> = [
  {
    name: "Github",
    link: "https://www.github.com/iamarav",
    slug: "github",
    icon: "github",
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/iamarav",
    slug: "facebook",
    icon: "facebook",
  },
  {
    name: "Twitter",
    link: "https://www.twitter.com/sachdeva_sahab",
    slug: "twitter",
    icon: "twitter",
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/sachdeva_sahab",
    slug: "instagram",
    icon: "instagram",
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/iamarav",
    slug: "linkedin",
    icon: "linkedin",
  },
  {
    name: "Tumblr",
    link: "https://sachdeva-sahab.tumblr.com/",
    slug: "tumblr",
    icon: "tumblr",
  },
  {
    name: "Mail",
    link: "mailto:me@gauravsachdeva.in",
    slug: "mail",
    icon: "mail",
  },
];
