import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26dc2889"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "left-menu flex md:flex-col" }
const _hoisted_2 = { class: "left-menu-title" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = { class: "menu" }
const _hoisted_6 = {
  key: 2,
  class: "external-link-notice",
  title: "External Link"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "sidemenu-footer mt-10" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_dropdown = _resolveComponent("menu-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, [
      _createElementVNode("div", {
        class: "name first-name",
        textContent: _toDisplayString(_ctx.Info.FirstName)
      }, null, 8, _hoisted_3),
      _createElementVNode("div", {
        class: "name last-name",
        textContent: _toDisplayString(_ctx.Info.LastName)
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("ul", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuLinks, (link) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "left-menu-link",
          key: link
        }, [
          (link.dropdown)
            ? (_openBlock(), _createBlock(_component_menu_dropdown, {
                key: 0,
                title: link.name,
                links: link.links
              }, null, 8, ["title", "links"]))
            : (_openBlock(), _createBlock(_resolveDynamicComponent(link.external ? 'a' : 'router-link'), {
                key: 1,
                rel: link.external ? 'noreferrer' : null,
                href: link.link,
                to: { name: link.routeName, params: link.routeParams },
                textContent: _toDisplayString(link.name)
              }, null, 8, ["rel", "href", "to", "textContent"])),
          (link.external)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                _createElementVNode("img", {
                  src: `/images/link-external-icon.svg`,
                  width: "20",
                  height: "20",
                  alt: "External Link"
                }, null, 8, _hoisted_7)
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createElementVNode("footer", _hoisted_8, [
      _createElementVNode("span", {
        class: "copyright",
        innerHTML: _ctx.FooterText
      }, null, 8, _hoisted_9)
    ])
  ]))
}