import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => {
      return import(/* webpackChunkName: "blog" */ "@/views/Blog.vue");
    },
    meta: {
      title: "Blog",
      metaTags: [
        {
          name: "description",
          content: "Welcome to my blog. I share my thoughts, interests and hobbies here :)",
        },
      ],
    },
  },
  {
    path: "/post/:id",
    name: "BlogPost",
    component: () => {
      return import(/* webpackChunkName: "blog-post" */ "@/views/BlogPost.vue");
    },
    meta: {
      title: "Blog Post",
      metaTags: [
        {
          name: "description",
          content: "Welcome to my blog. I share my thoughts, interests and hobbies here :)",
        },
      ],
    },
  },
  {
    path: "/tag/:tag",
    name: "TagPosts",
    component: () => {
      return import(/* webpackChunkName: "blog-tag-posts" */ "@/views/BlogTagPosts.vue");
    },
    meta: {
      title: "Posts by Tag",
      metaTags: [
        {
          name: "description",
          content: "Explore posts for this tag on my blog",
        },
      ],
    },
  },
]

export default routes
