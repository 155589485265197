import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open))
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        textContent: _toDisplayString(_ctx.title)
      }, null, 8, _hoisted_1),
      _withDirectives(_createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, kkey) => {
          return (_openBlock(), _createElementBlock("span", {
            key: kkey,
            class: "dropdown-link",
            textContent: _toDisplayString(link)
          }, null, 8, _hoisted_2))
        }), 128))
      ], 512), [
        [_vShow, _ctx.open && _ctx.links.length > 0]
      ])
    ]),
    _: 1
  }))
}