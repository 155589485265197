
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    tag: { type: String, default: `span` },
    title: { type: String, required: true },
    links: { type: Array, required: true },
  },

  data() {
    return { open: false };
  },
});
