/**
 * Function to calculate age in years
 *
 * @param {string} birthday
 * @returns age
 */
export function calculateAge(birthday: string): string | number {
  // birthday is a date
  const ageDifMs = Date.now() - (new Date(birthday) as any);
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}
