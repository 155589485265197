import { HeaderLinks as HeaderLinksType } from "@/types/Menu.type";
import { isBlogDomain, isPortfolioDomain } from "@/utils/domain";
import { PortfolioLinks } from "./portfolio-menu";
import { BlogLinks } from "./blog-menu";

export const HeaderLinks: HeaderLinksType = (() => {
  let $routes: HeaderLinksType = [];

  if (isPortfolioDomain()) {
    $routes = [...$routes, ...PortfolioLinks];
  } else if(isBlogDomain()) {
    $routes = [...$routes, ...BlogLinks];
  } 

  return $routes;
})();
