
import { defineComponent } from "vue";
import { SocialLinks } from "../config/socialLinks";

export default defineComponent({
  data() {
    return {
      links: SocialLinks,
    };
  },
});
