import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => {
      return import(/* webpackChunkName: "home" */ "@/views/Home.vue");
    },
    meta: {
      title: "Welcome",
      metaTags: [
        {
          name: "description",
          content: "Welcome to the Home",
        },
      ],
    },
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => {
      return import(/* webpackChunkName: "contact" */ "@/views/Contact.vue");
    },
    meta: {
      title: "Contact",
      metaTags: [
        {
          name: "description",
          content: "Contact me",
        },
      ],
    },
  },
  {
    path: "/skills",
    name: "Skills",
    component: () => {
      return import(/* webpackChunkName: "skills" */ "@/views/Skills.vue");
    },
    meta: {
      title: "Skills",
      metaTags: [
        {
          name: "description",
          content: "My Skills.",
        },
      ],
    },
  },
]

export default routes