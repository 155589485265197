import { HeaderLinks as HeaderLinksType } from "@/types/Menu.type";

export const PortfolioLinks: HeaderLinksType = [
    {
      name: "Home",
      routeName: "Home",
    },
    {
      name: "Skills",
      routeName: "Skills",
    },
    // {
    //   name: "Projects",
    //   routeName: "Home",
    // },
    {
      name: "Blog",
      external: true,
      link: "http://www.gauravsachdeva.in",
    },
    {
      name: "Contact",
      routeName: "Contact",
    },
  ];