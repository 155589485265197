import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74fe8106"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-none m-0" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, key) => {
      return (_openBlock(), _createElementBlock("li", {
        class: _normalizeClass(["social-link", `social-${link.slug}`]),
        key: key,
        title: link.name
      }, [
        _createElementVNode("a", {
          target: "_blank",
          href: link.link
        }, [
          _createElementVNode("img", {
            src: `/images/social-icons/${link.icon}.svg`,
            alt: link.name,
            rel: "noreferrer",
            width: "40",
            height: "40"
          }, null, 8, _hoisted_4)
        ], 8, _hoisted_3)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}