/**
 * CRITICAL AREA
 *
 * Please do not edit anything without discussing with admin
 *
 * I hope you know what you are doing
 */
import BlogRoutes from "@/router/modules/blog";
import PortfolioRoutes from "@/router/modules/portfolio";

export const Domains = {
  portfolio: {
    name: "Portfolio",
    slug: "me",
    subdomain: "me",
    routes: [
      ...PortfolioRoutes,
    ]
  },
  default: {
    name: "Default",
    slug: "default",
    subdomain: "www",
    routes: [
      ...BlogRoutes
    ]
  },
};

/**
 * DO NOT CHANGE THE FOLLOWING VALUE
 * 
 * @const defaultDomainLength
 * Domain length
 * route1.example.com => domain length = 3
 */
export const defaultDomainLength = 3;

