
import { defineComponent } from "vue";
import { HeaderLinks } from "@/config/menu";
import { FooterText } from "@/config/general";
import Info from "@/config/info";
import MenuDropdown from "./Links/MenuDropdown.vue";

export default defineComponent({
  components: { MenuDropdown },
  data() {
    return {
      menuLinks: HeaderLinks,
      FooterText,
      Info,
    };
  },
});
